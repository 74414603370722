<template>
  <r-app>
    <div
      class="page-loading d-flex h-center v-center color-warning-text flex-column"
      v-if="!lang_load"
    >
      <r-progress-circle size="100" indeterminate width="4"></r-progress-circle>
      <span class="mt-2">{{ $t("loading") }}</span>
    </div>
    <router-view v-else />
  </r-app>
</template>
<script>
import { App } from "./mixins/app";

export default {
  mixins: [App],
  data() {
    return {
      lang_load: false,
    };
  },
  created() {
    this.setDefault();
    this.getDefault();
    this.userInfo();
    this.$r.store.unit_price = "toman";
    this.$r.store["cart"] = this.$storage.get("carts", {});
    this.$r.store["cart_e"] = this.$storage.get("carts_e", {});
    this.$r.store.wish_list = this.$storage.get("wish_list", []);
    this.$r.icons.delete =
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.863 19.863">\n' +
      '    <path d="M14.9.414A84.326 84.326 0 0 0 6.6 0a48.886 48.886 0 0 0-4.912.248L0 .414" transform="translate(2.483 4.535)" style="stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.2px;fill:none"/>\n' +
      '    <path data-name="Vector" d="m0 2.458.182-1.084C.314.588.414 0 1.812 0h2.169c1.4 0 1.506.621 1.63 1.382l.182 1.076" transform="translate(7.035 1.655)" style="stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.2px;fill:none"/>\n' +
      '    <path data-name="Vector" d="M11.338 0 10.8 8.334c-.091 1.3-.166 2.309-2.475 2.309H3.013C.7 10.643.629 9.633.538 8.334L0 0" transform="translate(4.262 7.564)" style="stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.2px;fill:none"/>\n' +
      '    <path data-name="Vector" d="M0 0h2.756" transform="translate(8.549 13.656)" style="stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.2px;fill:none"/>\n' +
      '    <path data-name="Vector" d="M0 0h4.138" transform="translate(7.862 10.345)" style="stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.2px;fill:none"/>\n' +
      "</svg>";
    this.$r.icons.logout =
      '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M15 12L2 12M2 12L5.5 9M2 12L5.5 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
      '<path d="M9.00195 7C9.01406 4.82497 9.11051 3.64706 9.87889 2.87868C10.7576 2 12.1718 2 15.0002 2L16.0002 2C18.8286 2 20.2429 2 21.1215 2.87868C22.0002 3.75736 22.0002 5.17157 22.0002 8L22.0002 16C22.0002 18.8284 22.0002 20.2426 21.1215 21.1213C20.3531 21.8897 19.1752 21.9862 17 21.9983M9.00195 17C9.01406 19.175 9.11051 20.3529 9.87889 21.1213C10.5202 21.7626 11.4467 21.9359 13 21.9827" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>\n' +
      "</svg>";
  },
  watch: {
    all_lang_loaded(n) {
      if (n) {
        setTimeout(() => {
          this.lang_load = true;
        }, 100);
      }
    },
  },
};
</script>
<style lang="scss">
.page-loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-image: linear-gradient(45deg, #332665, #0ceae3);
  z-index: 100;
}
</style>
