<template>
  <r-laptop v-if="$r.breakpoint.mdAndUp"></r-laptop>
  <r-mobile v-else></r-mobile>
  <r-modal
    full-width
    class="px-2 px-md-10 px-lg-12"
    :closebtn="false"
    :model-value="$r.store.showCart || false"
  >
    <div class="color-white"><order-card show-action></order-card></div>
  </r-modal>
  <r-modal
    full-width
    class="px-2 px-md-10 px-lg-12"
    :closebtn="false"
    :model-value="$r.store.showCart_e || false"
  >
    <div class="color-white">
      <order-card-e show-action></order-card-e>
    </div>
  </r-modal>
  <r-btn v-show="show" v-scroll="set" class="color-one btn-up" fab @click="top">
    <r-icon v-html="$r.icons.chevron_up"></r-icon>
  </r-btn>
</template>

<script>
import { defineAsyncComponent } from "@vue/runtime-core";
import OrderCard from "../../components/orderCard";
import OrderCardE from "@/components/orderCard_e.vue";

export default {
  name: "index",
  components: {
    OrderCardE,
    OrderCard,
    RLaptop: defineAsyncComponent(() => import("./laptop")),
    RMobile: defineAsyncComponent(() => import("./mobile")),
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    if (!this.$r.store.categories) {
      this.$axios.get("/home/categories/").then(({ data }) => {
        this.$r.store.categories = data;
      });
    }
  },
  methods: {
    top() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    set() {
      if (window.scrollY > 20) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
.template-home {
  .router-link-exact-active {
    color: var(--color-one) !important;
  }
}
</style>
